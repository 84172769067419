









import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class GeneralSourceUrl extends Vue {
  @Prop() siteName?: string;

  @Prop() domain!: string;

  @Prop() url!: string;

  @Prop() meta?: OutlineMeta;
}
